import { useState } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";


// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Farazbit React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Farazbit React example components
import { Button, Input } from "@mui/material";
import { sendMessage } from "api/message";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useParams } from "react-router-dom";
// Data



function readAsImage(file) {
  return new Promise(resolve => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (e) {
        console.log(e.target);

        resolve(e.target.result)
      }
      reader.readAsDataURL(file);
    }
  })
}



function SendMessagePage() {
  const { id, username } = useParams();

  const [value, setValue] = useState("");
  const [image, setImage] = useState("");

  function handleImage(e) {
    readAsImage(e.target.files[0]).then(setImage)

  }
  async function handleSendMessage() {
    try {

      const parsedValue = value
        .replace(new RegExp("<p>", "gi"), "")
        .replace(new RegExp("</p>", "gi"), "\n")

        // .replace(new RegExp("<ol>", "gi"), "")
        // .replace(new RegExp("</ol>", "gi"), "")

        // .replace(new RegExp("<ol>", "gi"), "")
        // .replace(new RegExp("</ol>", "gi"), "")

        // .replace(new RegExp("<li>", "gi"), "")
        // .replace(new RegExp("</li>", "gi"), "")

        // .replace(new RegExp("<ul>", "gi"), "")
        // .replace(new RegExp("</ul>", "gi"), "\n")

        // .replace(new RegExp("<u>", "gi"), "")
        // .replace(new RegExp("</u>", "gi"), "\n")

        .replace(/\<span\>.*\<\/span\>/gi, "")

      try {
        await sendMessage(parsedValue, id, image)
        alert("Message sent successfully")
        setValue("")
      } catch (error) {
        alert("Could not send message")
      }

    } catch (error) {
      console.log(error);

    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Send Message to {username || "All"}
                </MDTypography>
              </MDBox>
              <MDBox mx={2} py={3} px={2} pt={3}>
                <Input onChange={handleImage} fullWidth type="file" />
                <div style={{ display: "flex", justifyContent: "center", padding: 20 }}>
                  {image && <img src={image} style={{ width: "70%" }} />}
                </div>
              </MDBox>
              <MDBox mx={2} py={3} px={2} pt={3}>
                <ReactQuill theme="snow" modules={{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'blockquote']
                  ],
                }} value={value} onChange={setValue} />
              </MDBox>
              <MDBox mx={2} py={3} px={2} pt={3}>
                <Button onClick={() => handleSendMessage()}>
                  Send
                </Button>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SendMessagePage;
