/**
=========================================================
* Farazbit React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Farazbit React components
import { Button, Input } from "@mui/material";
import MDBox from "components/MDBox";

// Farazbit React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useState } from "react";
import { ChangePasswordApi } from "api/auth";
import { ChangeUserNameApi } from "api/auth";




// Data

// Dashboard components

function Admin() {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  function changeUsername() {
    ChangeUserNameApi(username).then(() => {
      localStorage.auth = "";
      localStorage.remember = "";
      window.location.reload()
      alert("Success")
    }).catch(() => alert("Failed to connect server"))

  }
  function changePassword() {
    ChangePasswordApi(password).then(() => {
      localStorage.auth = "";
      localStorage.remember = "";
      window.location.reload()
      alert("Success")
    }).catch(() => alert("Failed to connect server"))
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div style={{ backgroundColor: "white", padding: 20 }}>
          <div>
            <Input value={username} onChange={e => setUsername(e.target.value)} placeholder="username" />
            <Button onClick={changeUsername}>Change Username</Button>
          </div>
          <br />
          <div>
            <Input value={password} onChange={e => setPassword(e.target.value)} placeholder="password" />
            <Button onClick={changePassword}>Change Password</Button>
          </div>

        </div>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Admin;
