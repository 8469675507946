import { ApiAddress } from "config";

export async function login(username, password) {
    return (await fetch(ApiAddress + "/panel/login", {
        body: JSON.stringify({ username, password }),
        method: "post",
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.auth }
    })).json();
}


export async function ChangeUserNameApi(username) {
    return (await fetch(ApiAddress + "/panel/username", {
        body: JSON.stringify({ username }),
        method: "post",
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.auth }
    })).json();
}
export async function ChangePasswordApi(password) {
    return (await fetch(ApiAddress + "/panel/password", {
        body: JSON.stringify({ password }),
        method: "post",
        headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.auth }
    })).json();
}