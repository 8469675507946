const { createContext, useState, useEffect } = require("react");

export const authContext = createContext({ setAuth: () => { }, auth: "" })


export function AuthContextProvider({ children }) {
    const [auth, setAuth] = useState("")
    useEffect(() => {
        if (localStorage.getItem("remember") == "1")
            setAuth(localStorage.getItem("auth") || "")
        else {
            localStorage.setItem("auth", "")
            localStorage.setItem("remember", "")

        }
    }, [])
    const authData = {
        auth, setAuth: (value, rememberMe = false) => {
            setAuth(value);
            localStorage.setItem("auth", value)
            localStorage.setItem("remember", rememberMe ? 1 : undefined)
        }
    }
    return <authContext.Provider value={authData}>{children}</authContext.Provider>
}